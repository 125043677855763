<template>
	<main-tabs :links="links">
		<keep-alive>
			<router-view></router-view>
		</keep-alive>
	</main-tabs>
</template>

<script>
	export default {
		data() {
			return {
				links: [{
					to: {
						path: '/ad-strategy/config'
					},
					label: '策略配置'
				}, {
					to: {
						path: '/ad-strategy/history'
					},
					label: '策略生效记录'
				}]
			}
		}
	}
</script>
